<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ category != null ? $t('categories.update_btn') : $t('categories.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="12">
          <text-input
              id="package-ad"
              v-model="form.name"
              :label="$t('categories.form.name')"
              :placeholder="$t('form.text.placeholder')"
              name="package-ad"
              rules="required"
          />
        </b-col>


        <b-col md="6" v-if="false">
          <div @click="$refs.imageinput.click()">
            <text-input
                id="main-image"
                v-model="form.image"
                readonly
                name="image"
                :label="$t('categories.form.image')"
                :placeholder="$t('form.image.placeholder')"
                is-append
                append-class="append-btn"
                :append-text="$t('form.image.browse')"
            />
          </div>
          <input
              ref="imageinput"
              type="file"
              accept="image/png, image/jpeg"
              style="display: none"
              @change="uploadImageAction($event)"
          />
        </b-col>
      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="category!=null ?$t('btn.update'):$t('btn.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions} from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    category: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      form: {
        name: '',
        vendor_id: ''
      },
      showSuccessModal: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    category(newVal) {
      if (newVal != null) {
        this.form.name = newVal.name;
      } else {
        this.form.name = '';
      }
    },
  },
  created() {
    if (this.category != null) {
      this.form.name = this.category.name;
      this.form.image = this.category.image;
    }
  },
  methods: {
    ...mapActions({
      create: "vendor/categories/create",
      update: "vendor/categories/update",
    }),
    submitOrder() {
      if (this.category == null) {
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.show = false;
          this.showModal = true;
          this.form = {
            image: '',
            name: ''
          }
          this.$store.commit('loadingFinish', null, {root: true});
        });
      } else {
        this.update({
          id: this.category.id,
          name: this.form.name,
          image: this.form.image,
        }).then(() => {
          this.$emit('onComplete', true);
          this.show = false;
          this.showModal = true;
          this.form = {
            image: '',
            name: ''
          }
          this.$store.commit('loadingFinish', null, {root: true});
        });
      }
    },

    uploadImageAction(event, type = 'upload') {
      this.$store.commit('loadingStart', null, {root: true});
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      if (this.dropped == 0) files = event;
      else files = event.currentTarget.files

      if (!files.length) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);

        this.$store.dispatch('utilities/uploadFiles', formData).then(response => {
          this.form = {
            ...this.form,
            image: response,
          };
          this.$store.commit('loadingFinish', null, {root: true});
        });
      }
    },

    closeModal() {
      this.show = false;
      this.form = {
        image: '',
        name: ''
      }

    },
  },
};
</script>
